/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear {  display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal {  display: none; width : 0; height: 0; }

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

@import '_theme.scss';

mat-radio-button.m-0,
mat-checkbox.m-0 {
    label {
        margin: 0 !important;
    }
}

.rtl {
    input[type="tel"] {
        text-align: right;
    }
}

@mixin md-icon-size($size: 24px) {
    font-size: $size;
    height: $size;
    width: $size;
    line-height: $size;
}

.address-tool-tip {
    font-size: 14px;
}

.mat-mdc-button-persistent-ripple {
    display: none;
}


.to-side-menu {
    .mat-mdc-button-persistent-ripple {
        display: none;
    }
}

mat-spinner {
    &.light-grey {
		circle {
			stroke:map-get($default-theme, 400) !important;
		}
	}
    &.dark-grey {
		circle {
			stroke:map-get($default-theme, 900) !important;
		}
	}
	&.light-blue {
		circle {
			stroke:$primary-text !important;
		}
	}
	&.disabled-spinner {
        margin: 0 auto;
    }
}

mat-icon {

    &.xs {
        @include md-icon-size(12px);
    }

    &.sm {
        @include md-icon-size(14px);
    }

    &.base {
        @include md-icon-size(16px);
    }

    &.md {
        @include md-icon-size(20px);
    }

    &.lg {
        @include md-icon-size(30px);
    }

    &.xl {
        @include md-icon-size(48px);
    }

    &.xxl {
        @include md-icon-size(180px);
	}

	&.close {
		color: map-get($default-theme, 1000);
		text-shadow: none;
	}
}

mat-chip-list.mat-mdc-chip-list-sm {
    .mat-mdc-chip {
        font-size: 12px;
    }
}

mat-chip-list.center-chips {
    .mat-mdc-chip-list-wrapper {
        justify-content: center;
    }
}

mat-snack-bar-container.mat-mdc-snack-bar-container.add-to-basket-snackbar {
    height: 3rem;
    min-height: initial;
    width: initial;
    max-width: initial;
    position: fixed;
    right: 0.5rem;
    left: 0.5rem;
    bottom: 5rem;
    margin: 0;
    background: map-get($primary-theme, 100);
    color: #000;
    overflow: hidden;
    box-shadow: 0 0 1rem 2px #888;
    border: 0;

    ._IOS.cordova & {
        bottom : 7rem;
    }

    .mat-mdc-simple-snackbar-action {
        .ltr & {
            direction: rtl!important;
        }
    }

    simple-snack-bar {
        & > span {
            display: block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .mat-mdc-simple-snackbar-action {
            color: #000;

            .mdc-button__label {
                font-size: 1rem;
            }
        }
    }
}

.mat-icon-button {
    &.loading {
        position: relative;

        &:before {
            content: ' ';
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            border: 2px solid #0faeff;
            border-top: 2px solid transparent !important;
            border-radius: 100%;
            animation: load-animate infinite linear 1s;
        }
    }
}

.btn {
	&.btn-trans {
		border-radius: 2rem;
		height: 3rem;
		@include card-shadow;
		background-color: rgba(239, 239, 239, 0.33);
		/*border: 1px solid map-get($default-theme, 0);*/
		color: map-get($default-theme, 0);

        &.dialog-button {
            background-color: map-get($primary-theme, 500) !important;
            color: map-get($default-theme, 0) !important;
        }
	}

	&.btn-link {
		height: 3rem;
		color: $primary-text;

		&:hover {text-decoration: none}
	}
}

.mat-mdc-text-field-wrapper:hover .mat-mdc-form-field-focus-overlay {
    opacity: 0 !important;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent !important;
}

.mat-mdc-split-button {
    display: flex;

    &.rounded {
        border-radius: 25px !important;
        align-items: center;
        justify-content: center
    }

    &.shadow {
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    }

    > button {
        flex-grow: 1;
        box-shadow: none !important;

        &:first-child {
            border-radius: 0 25px 25px 0 !important;

            .ltr & {
                border-radius: 25px 0 0 25px !important
            }
        }

        &:not(:first-child) {
            margin-right: 2px;

            .ltr & {
                margin-right: unset;
                margin-left: 2px;
            }
        }

        &:last-child {
            border-radius: 25px 0 0 25px !important;

            .ltr & {
                border-radius: 0 25px 25px 0 !important;
            }
        }
    }
}

.mat-mdc-single-button {
    display: flex;

    &.rounded {
        border-radius: 25px !important;
        align-items: center;
        justify-content: center
    }

    &.shadow {
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    }

    > button {
        flex-grow: 1;
        box-shadow: none !important;
        border-radius: 25px !important;
    }
}

.mat-mdc-drawer-container {
	background: rgba(0,0,0,0.5);
}

.mat-mdc-drawer-content {
	position:initial!important; // Required for the elastic page to behave properly on iOS (restaurant page > strech the image when scrolling)
}

.mat-mdc-optgroup-label {
    // For some weird reason, the font-family is not inherited here, unless we specify it
    font-family: inherit;
}

.mat-mdc-button, .mdc-button--unelevated, .mdc-button--unelevated, .mat-mdc-outlined-button {
    font-family: inherit;

    &.active {
        .mat-mdc-button-focus-overlay {
            opacity: 1;
        }
    }

    &.rounded {
        border-radius: 25px !important;
    }

    &.b-drak {
        border-color: map-get($default-theme, 600);
    }

    ._next {
        background: rgba(255,255,255,0.4);
        border-radius: 20px;
    }

    &.block {
        display: block;
        width: 100%;
    }

    &.lg {
        height: 50px;

        .mdc-button__label {
            font-size: 20px;
            font-weight: normal;
        }
    }

    &.btnpay {
        background: #9C80A8 !important;
        color: map-get($default-theme, 0) !important;

        &.loading:before {
            border-color: map-get($default-theme, 0);
        }
    }

    &.loading {
        position: relative;
        pointer-events:none;
        &:before {
            content: ' ';
            width: 26px;
            height: 26px;
            position: absolute;
            margin-left: -13px;
            left: 50%;
            margin-top: -13px;
            top: 50%;
            border: 2px solid #0faeff;
            border-top: 2px solid transparent !important;
            border-radius: 100%;
            animation: load-animate infinite linear 1s;
        }
    }
}


.mat-mdc-form-field.width-auto {
    .mat-mdc-form-field-infix {
        width: auto;
    }
}

.condensed-fields {
    .mat-form-field-appearance-outline .mat-mdc-form-field-wrapper {
        margin: 6px 0;
        padding: 0 !important;
    }
}

.form-field-block-light {
    display: block !important;

    .mat-mdc-form-field-outline {
        background: transparent;
    }
}

.mat-form-field-appearance-outline .mat-mdc-form-field-prefix, .mat-form-field-appearance-outline .mat-mdc-form-field-suffix {
    display:flex;
}
.block-ui-wrapper {
	background: rgba(250, 250, 250, 0.1) !important;

	.dark-block & {
		background: rgba(0, 0, 0, 0.7) !important;
	}

    .white-block & {
        background: white !important;
    }

	.block-ui-spinner, .block-ui-template {
		top: 50%;

		.loader, .loader:after {
			border-left-color: #CCC;
			border-width: 5px;
			width: 80px;
			height: 80px;
		}

		> .message {
			color: $link-color;
			white-space: pre;
		}
	}
}

.auto-complete-container {
    > .dropdown-menu {
        > .dropdown-item {
            padding: 12px 20px;
        }
    }
}

.row.row-sm {
    margin-right: -10px;
    margin-left: -10px;

    > div {
        padding-right: 10px;
        padding-left: 10px;
    }
}

.list-group {
    padding: 0 !important;
}

.list-group-item {
    background-color: map-get($default-theme, 0);

    &.active {
        background-color: $primary-background-light!important;
        border: none!important;
        color: black!important;
        border-radius: 0.3rem;
    }
}

.btn-primary:hover,
.btn-primary {
    color: map-get($default-theme, 0);
    background-color: $primary-text;
    border-color: $primary-text;

    &.disabled, &:disabled {
        color: map-get($default-theme, 0);
        background-color: $primary-text;
        border-color: $primary-text;
        opacity:0.5;
    }
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
	color: map-get($default-theme, 0);
	background-color: $primary-text;
	border-color: $primary-text;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(41, 205, 255, 0.50);
}

.btn-primary:focus, .btn-primary.focus {
	box-shadow: 0 0 0 0.2rem rgba(41, 205, 255, 0.50);
}

.btn-outline-tabit {
	color: map-get($default-theme, 600);
	border-color: $primary-text;
	background: transparent;
	&.active {
		color: map-get($default-theme, 0);
		background-color: $primary-text
	}

}

.form-control:focus {
    //box-shadow: inset 0 0 0.4rem 0 rgba(41, 205, 255, 0.25);
    background-color: initial;
}

.btn-group {
	> button.btn {
		&:focus {
			box-shadow: none;
		}

		&:active {
			box-shadow: none;
			background-color: inherit;
			border-color: inherit;
		}
	}
}

// .mat-mdc-dialog-container {
//     border-radius: 0 !important;
// }

// center main-message-dialog
._mobile .cdk-global-overlay-wrapper.center-dialog-container {
    align-items: center !important;
}

.cdk-overlay-pane:not(.mat-mdc-dialog-panel) {

    .web-selection-bar-vertical {
        width: auto!important;
        position: fixed !important;
    }

    .web-selection-bar.vertical:not(.mobile) {
        width: auto!important;
        position: fixed !important;
    }

    @media (prefers-color-scheme: dark) {
        --mdc-list-list-item-label-text-color: #000000;
        --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--primary-theme-500);
    }
}

.cdk-overlay-pane {

    &.main-message-dialog-container {
        height: initial !important;
        // min-height: 40vh !important;
        max-width: initial !important;
        margin-top: 0 !important;
        justify-content: center;
        @include main-dialog-border-radius;

        @media screen and (min-width: 768px) {
            align-items: center;
            margin-top: 5rem !important;
        }

        .mat-mdc-dialog-surface {
            border-radius: 10px;
        }

        .mat-mdc-dialog-container {
            // min-height: 20rem;
            width: 30rem;
            min-width: 20rem;
            max-width: 80vw;
            display: flex;
            justify-content: center;
            padding: 0;
            width: 80%;
            @include main-dialog-style;
        }

        svg {
            // change the 'main-message-dialog' svg icons colors with feColorMatrix 
            filter:url(#primary-app-color-svg-filter);
        }

        // &.x-button-visible .mat-mdc-dialog-container {
        //     padding-top: 1.5rem;
        // }

        @media (prefers-color-scheme: dark) {
            body:not(.cordova._ANDROID) {
                --mdc-dialog-container-color: #424242;
                .mdc-dialog__surface {
                    background: transparent;
                }
            }



            body.cordova._ANDROID & {
                .mat-mdc-unelevated-button:not(:disabled) { 
                    color: white  !important;
                }
            }
        }

        .cordova.android :host & {
            .mdc-dialog__surface { 
                background-color: white !important;
            }
        }
    }
    &.menu-selection-dialog {
        .mat-mdc-dialog-surface {
            padding: 20px 0;
        }
        .mdc-dialog__surface {
            background: transparent;
            box-shadow: none;
        }
        mat-dialog-container {
            padding: 0 24px !important
        }
        .mdc-list-item__primary-text {
            word-wrap: break-word;
            white-space: normal;
            overflow-wrap: break-word;
        }
    }

    &.secondary-message-dialog-container {
        max-width: 80vw !important;
        @include main-dialog-border-radius;

        .mat-mdc-dialog-container {
            height: 18.5rem;
            min-height: 16rem;
            max-width: 80vw;
            display: flex;
            justify-content: center;
            padding-top: 1rem;
            @include main-dialog-style;
        }
    }

    &.sign-in-dialog-container {
        max-width: initial !important;
        margin-top: 0 !important;

        @media screen and (min-width: 768px) {
            align-items: center;
            margin-top: 5rem !important;
        }


        ._desktop & { 
            .mdc-dialog__surface {
                height: auto;
                min-height: auto;
            }
        }

        .mat-mdc-dialog-container {
            max-height: 38rem;
            min-height: 16rem;
            // width: 20rem;
            display: block;
            justify-content: center;
            overflow: scroll;
            @include main-dialog-style;
            border-radius: 0.5 !important;

            // To prevent unintended visual transitions (highlight-background), we ensure that the background remains white when it changes, thereby avoiding the initiation of the transition
            &:active:not(:focus-within) {
                background-color:map-get($default-theme, 0);
            }
        }
    }

    &.to-loyalty-dialog {
        width: 400px;

        ._desktop & {
            // We use here 350px as for the loyalty banner width
            // in loyalty-banner.scss
            width: calc(5% + 350px);
        }

        .mdc-dialog__surface {
            border-radius: 0!important;
        }
    }

    /* gift card shop */
    &.gift-card-payment-dialog-container {
        width: 100vw;
        max-width: 100vw !important;
        height: calc(100vh - 4rem) !important;
        margin: 2rem !important;

        &.embedded-payment-iframe {
            height: 80vh !important;
            min-height: 30rem !important;
            width: 35vw !important;
            max-width: 22rem !important;
            min-width: 22rem !important;

            @media screen and (min-width: 768px) {
                height: 80vh !important;
                min-height: 30rem !important;
                width: 35vw !important;
                max-width: 22rem !important;
                min-width: 22rem !important;
            }

            &.is-ios {
                height: 80vh !important;
            }
        }

        &.is-ios {
            height: calc(100vh - 5rem) !important;
        }

        @media screen and (min-width: 768px) {
            width: 45vw;


        }

        .mat-mdc-dialog-container {
            padding: 0;
            background: #fff !important;
        }
    }

    &.splash-screen-dialog {
        max-width: initial !important;
        width: 80vw !important;

        mat-dialog-container {
            background: $card-background !important;
            overflow-y: hidden;
            width: 100%;
            background:rgba(255,255,255,1);
            @include card-shadow;
            padding: 0 !important;
            border-radius:15px !important;
        }

        @media (min-width: 1024px) {
            max-width: 500px !important;
            width: 100% !important;
            height: 80%;
            margin: 0 auto !important;
            top: 10vh;

            mat-dialog-container {
                width: 100%;
            }
        }
    }

    &.accessibility-dialog-container {
        mat-dialog-container {
            .panel {
                box-shadow: none;
            }
        }
    }

    &.to-delay-dialog-container {
        max-width: 80vw !important;
        @include main-dialog-border-radius;

        .mat-mdc-icon-button {
            height: 40px;
        }

        .mat-mdc-button-persistent-ripple {
            display: none;
        }

        .mat-mdc-dialog-container {
            max-height: auto;
            min-height: 15rem;
            max-width: 80vw;
            
            @include main-dialog-style;
            border-radius: 20px;

            .mat-mdc-dialog-surface {
                border-radius: 20px;
                padding: 1rem;
            }

        }
    }
    &.future-orders-dialog {
        ._mobile & {
            .mat-mdc-dialog-surface {
                border-radius: 0;
            }
        }
        ._desktop & {
            .mat-mdc-dialog-surface {
                background-color: transparent!important;
            }
            
        }

    }
}
app-to2-extras {
    .mat-mdc-icon-button svg {
        width: 100% !important;
        height: 100% !important;
    }
}


.cdk-overlay-backdrop.main-message-dialog-error-backdrop {
    background-color: map-get($default-theme, A400);
}

/*
#toastr-container {
    z-index: 5!important;

    > .toastr {
		width: 100%!important;
        margin: 0!important;
        padding: 2rem!important;
        padding-bottom: calc(env(safe-area-inset-bottom) + 2rem)!important;

		text-align: center;
        border-radius: 0!important;

		.toastr-close-button {
			position: absolute;
			left: 0rem;
			right: auto!important;
			top: 0rem!important;
			font-size: 2rem;
			text-shadow: none;
			line-height: 3rem;
			height: 3rem;
			width: 3rem;
		}

		span {
			font-size: 1.2rem;
			line-height: 1.2rem;
		}

		&.toastr-info, &.toastr-warning, &.toastr-error, &.toastr-success, &.toastr-custom {
			color: white;
			background-color: $primary-background;
			background-image: none !important;
			box-shadow: none !important;
			min-height: none;

			.toastr-title {
				font-size: 1.4rem;
				padding: 1rem 0 0 0;
			}
		}
		&.toastr-custom {
			background-color: rgba(0,0,0,0.9);
			background-image: none !important;
			box-shadow: none !important;
			min-height: none;

			.toastr-title {
				color: white;
			}

			a {
				color: white;
				display:block;
				padding: 1rem 0;
				text-decoration: none;
				cursor: pointer;

				&:hover {
					text-decoration: none;
				}

				img {
					width: 50%;
					display: block;
					margin: 0 auto 1rem auto;
				}
			}
		}
	}
}
*/

/* TABIT ORDER OVERRIDES */

.alert-success {
    border: none;
    background-color: map-get($primary-theme, 100);
    color: black;
}

.text-success {
    color: $primary-text !important;
}

.border-bottom {
    border-color: $separator !important;
}

.to-fullscreen-dialog {
    width: 100vw !important;
    min-width: 100vw !important;
    max-width: 100vw !important;
    height: 85vh;
    min-height: 85vh !important;
    max-height: 85vh !important;
    background: map-get($default-theme, 0);

    &::after {
        content: "";
        opacity: 0.2;
        top: $toolbar-height;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: -1;
    }

    mat-dialog-container {
        display: inline;
        background: transparent;
        padding: 0;
    }
}

.to-bundle-dialog-backdrop {
    background:rgba(0,0,0,0.7);
}

.to-bundle-dialog {
    mat-dialog-container {
        border-radius: 0.5rem !important;
        padding: 0;

        .mat-mdc-dialog-title {
            margin: 0;
            padding: 15px;
            font-size: 24px;

            .header-close-icon {
                body.ltr & {
                    left: unset !important;
                    right: 0.6rem;
                }
            }
        }

        .mat-mdc-dialog-content {
            padding: 0;
            margin: 0;
            // border: 1px solid #dee2e6 !important;
            border-width: 1px 0;
        }

        .mat-mdc-dialog-actions {
            margin: 0;
            padding: 10px;
        }
    }
}

.to-inner-dialog {
    .mat-mdc-dialog-container {
        padding:0;
    }
}

.to-mobile-dialog {
    height: 100%;
    width: 100%;
    max-height:100vh;
    display: flex;
    flex-direction: column;

    .mat-mdc-button-persistent-ripple {
        display: none;
    }

    .to-dialog-header {
        @include title-shadow;
        display: flex;
        align-items: center;
        z-index: 2;

        padding: 0.5rem;

        .cordova & {
            padding-top: calc(env(safe-area-inset-top) + 1rem)!important;

            &.rewards-dialog {
                padding-top: 1rem !important;
            }
        }
        .cordova :host & {
            padding-top: calc(env(safe-area-inset-top) + 1rem)!important;
        }
     
        .cordova.android :host & {
            padding-top:2rem;
        }

        > ._title {
            font-size: 20px;
            font-weight: 500;
            padding: 0;
        }
    }

    :not(app-tou-offer-item-dialog) {
        .to-dialog-header {
            .cordova.android & {
                padding-top:2rem;
            }
        }
    }

    .to-dialog-actions {
        @include title-shadow;

        display: flex;
        align-items: center;
        z-index: 2;
        width: 100%;

        padding: 1rem;

        .cordova & {
            padding-bottom: calc(env(safe-area-inset-bottom) + 1rem);
        }
        .cordova :host & {
            padding-bottom: calc(env(safe-area-inset-bottom) + 1rem);
        }
        .cordova.android &:not(.no-viewport-spacing) {
            padding-bottom:2rem !important;
        }
        .cordova.android :host & {
            padding-bottom:2rem !important;
        }

        .add-remove-actions {
            display: flex;
            flex-flow: row;
            align-items: center;
            width: 25%;
            color: map-get($primary-theme, 500);

            .item-quantity {
                color: map-get($primary-theme, 0);
            }
        }

        .to-summary-button {
            @include card-shadow;
            display: flex;
            flex-flow: row;
            justify-content: center;
            width: 75%;
            padding: 1.2rem;
            font-size: 1rem;
            background: map-get($primary-theme, 500);
            border-radius: 0.5rem;
            letter-spacing: 0;
        }
        .dialog-add-button {
            background: map-get($primary-theme, 500);
        }
    }

    :not(app-tou-offer-item-dialog) {
        .to-dialog-actions {
            .cordova.android & {
                @include cordova-padding-bottom;
            }
        }
    }
}

._mobile {
    .to-dialog-actions {
        padding: 0;
        box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.4);
        
        .add-remove-actions {
            height: 100%;
            width: 40%;
            justify-content: center;
            // background: map-get($default-theme, 0);
            font-size: 1.25rem;

            button {
                // font-size: 23px;
                height: 30px;
                padding: 0;
                // width: 28px;

                mat-icon {
                    height: 100%;
                    width: 100%;
                }

                svg {
                    transform: scale(1.2);
                }
            }
        }

        .item-quantity {
            color: map-get($primary-theme, 500);
        }

        .to-summary-button {
            height: 100%;
            width: 60%;
            align-items: center;
            border-radius: 0;
            font-size: 1.2rem;
            font-weight: 100;
        }
    }
}

.mat-mdc-menu-item-text {
    font-family: 'Rubik',sans-serif !important
}

.mat-mdc-dialog-title {
    font-family: 'Rubik',sans-serif;
    .to-delay-dialog-container &{
        font-family: 'Rubik',sans-serif !important
    }
}
.mat-list-item-content {
    font-family: 'Rubik',sans-serif;
}

/* MAT-SELECT CUSTOM STYLING */

.mat-mdc-select-panel {
    &.mat-select-custom-class {

        transform: initial!important;
        padding-bottom: 3rem;

        @media only screen and (max-width: 767px) {
            max-height: 50vh;
            top: calc(50% - 25vh);
        }

        mat-option[value="manual-address"] {
            --mdc-list-list-item-label-text-color:white;
            justify-content: center;
            display: flex;
            position: absolute;
            bottom: 0;
            z-index: 1;
            width: auto;
            //min-width:calc(100% + 32px);
            min-width:100%;
            height: 3rem;
            padding: 0 0 0 1rem;
            color: white;
            text-align: center;
            font-weight: bold;
            background-color: map-get($primary-theme, 500)!important;

            .mdc-list-item__primary-text {
                margin-left: 0;
            }

            mat-icon {
                margin: 0;
            }
        }
        .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) {
            padding: 0 1rem 0 1rem !important;
        }
        .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text  {
            color: inherit !important;
        }
    }
}

// the ignore-theme didn't work for this selector because it gets an explicit "inherit" color from Material. So in light mode, always paint black.
@media (prefers-color-scheme: light) {
    .mat-mdc-calendar-controls .mdc-button__label {
        color: #000 !important;
    }
}

@media (prefers-color-scheme: dark) {
    #default-branding:not(:has(.cordova)), #custom-branding:not(:has(.cordova)) { 

        --mdc-text-button-label-text-color: #FFF;
        --mat-select-panel-background-color: var(--theme-100);
        online-reservations, .menu-selection-dialog {
            --mdc-filled-text-field-label-text-color: #fff;
            --mdc-filled-text-field-input-text-color: #fff;
            --mdc-filled-text-field-active-indicator-color: #fff;
            --mat-select-enabled-trigger-text-color: #fff;
            --mdc-dialog-subhead-color: #fff;
            --mdc-list-list-item-focus-label-text-color: #fff;
            --mdc-filled-text-field-hover-label-text-color: #fff;
            --mdc-filled-text-field-hover-active-indicator-color: #fff;
            --mdc-list-list-item-hover-label-text-color: #fff;
            --mat-option-label-text-color: #fff;
            --mat-full-pseudo-checkbox-unselected-icon-color: #fff!important;
            --mat-select-panel-background-color: var(--theme-100);
            --mdc-text-button-label-text-color: #FFF;
        }
    }
}

.mdc-list-item__primary-text, 
.mat-mdc-dialog-container .mdc-dialog__title  {
    color: inherit !important;
    // font-family: inherit !important;
    line-height: inherit !important;
    // font-size: inherit !important;
    font-weight: inherit !important;
    letter-spacing: inherit !important;
}
.mdc-text-field--disabled {
    background-color: inherit !important;
    .mdc-text-field__input { 
    color: inherit !important;
    }
    .mdc-floating-label--float-above {
        color: inherit !important;
    }
}

.mat-mdc-option {
    color: inherit !important;
}

// material dark-theme overrides
body {
    --mat-option-label-text-font: 'Rubik',sans-serif !important;
    --mat-option-label-text-tracking: 0 !important;
    --mat-form-field-focus-state-layer-opacity: 0 !important;
    --mdc-list-list-item-label-text-color: inherit !important;
}

#default-branding body {
    --mdc-snackbar-container-color: var(--primary-theme-500);
    .mdc-dialog__title::before {
        content: none;
    }
    @media (prefers-color-scheme: dark) {

        mat-dialog-container {
            background: map-get($default-theme, 100);

            .to-mobile-dialog .to-dialog-actions .to-summary-button {
                background-color: map-get($primary-theme, 500);
                // color: map-get($primary-theme, 1000);
            }

            app-keypad-dialog {
                .key-pad .btn-keypad > button {
                    background: transparent;
                }
            }
        }

        .to-splash-dialog.sites-dialog .dialog-container .close-button {
            background-color: map-get($default-theme, 0);
        }

        .to-loyalty-dialog mat-dialog-container {
            background: transparent;
        }

        .animation-dialog-dark mat-dialog-container {
            background: transparent;
        }

        .checkout-stage-container .to-tbl-extras-row button {
            color: map-get($primary-theme, 500);

            &:disabled {
                color: rgba(0, 0, 0, 0.26);
            }
        }

        mat-snack-bar-container.mat-mdc-snack-bar-container.add-to-basket-snackbar {
            background: map-get($primary-theme, 100);
            box-shadow: 0 0 1rem 2px #888;
        }


        .mat-mdc-step-header {
            .mat-mdc-step-label.mat-mdc-step-label-active .mat-mdc-step-text-label {
                color: #959595;
            }

            .mat-mdc-step-icon:not(.mat-mdc-step-icon-selected):not(.mat-mdc-step-icon-state-done) {
                background-color: rgba(0, 0, 0, 0.54);
            }
        }

        app-menu-item {
            background: initial;
        }

        app-my-history .mat-expansion-panel {
            background: initial;
        }

        .block-ui-wrapper {
            background: rgba(0, 0, 0, 0.70) !important;
        }

        to-basket .mat-expansion-panel {
            background: #000;
        }

        .cdk-overlay-pane.to-bundle-dialog mat-dialog-container app-tou-bundle .bundle-dialog-container mat-dialog-actions button {
            background: map-get($primary-theme, 500);
            color: #fff;
        }

        .cdk-overlay-pane.to-inner-dialog mat-dialog-container app-tou-offer-item-dialog .dialog-add-button {
            background: map-get($primary-theme, 500);
        }

        .checkout-contact-container .gratuity-options .gratuity-options-title,
        .to-mod-container .modifiers-group-increment-box ._info,
        app-to5-pay .payment-divider .new-payment {
            color: #fff;
        }

        order-summary .order-item svg {
            g,
            path {
                fill: #fff !important;
            }

            #Path {fill: transparent !important;}
        }

        web-selection-bar .vertical .mat-mdc-button.action-button {
            background: map-get($primary-theme, 500);
            color: #fff;
        }

    }

    .to-offer-dialog {
        background: map-get($default-theme, 0);
        .mdc-dialog__surface {
            background: map-get($palette-neutral, 10);
            border-radius: 0;
        }
    }
    .mat-mdc-icon-button .mat-mdc-button-persistent-ripple::before {
        display: none;
    }
}

#custom-branding body {
    --mdc-snackbar-container-color: var(--primary-theme-500);
    .mdc-dialog__title::before {
        content: none;
    }

    .to-offer-dialog {
        background: map-get($default-theme, 0);
        .mdc-dialog__surface {
            border-radius: 0;
        }
    }

    .mdc-dialog__surface {
        background: map-get($palette-neutral, 10);
    }

    .animation-dialog-dark mat-dialog-container {
        background: transparent;
    }

    .mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label, .mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-floating-label--float-above {
        color: map-get($default-theme, 500);
    }
    .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
        border-bottom: none
    }

    .mat-mdc-icon-button .mat-mdc-button-persistent-ripple::before {
        display: none;
    }
}

.profile-dialog {
    mat-dialog-container {
        padding-right:0px!important;
        padding-left:1px!important;
    }
    .mat-mdc-dialog-container {
        padding-top: 0;
    }
}

#glassix-widget-iframe-container {
        @media only screen and (max-width: 767px) {
            padding-top: 60px;
        }
    
        .cordova & {
            padding-top: env(safe-area-inset-top, 35px);
        }
    
        .cordova :host & {
            padding-top: env(safe-area-inset-top, 35px);
        }
    
        .cordova.android & {
            padding-top: 35px;
        }
    
        .cordova.android :host & {
            padding-top: 35px;
        }
        
}

.mat-mdc-text-field-wrapper {

    &.mdc-text-field--outlined {

        .mdc-notched-outline--upgraded {

            .mdc-floating-label--float-above {

                --mat-mdc-form-field-label-transform: translateY(calc(calc(5px + var(--mat-form-field-container-height) / 2) * -1)) scale(var(--mat-mdc-form-field-floating-label-scale, 0.75)) !important;

            }

        }

    }
}

