﻿
@import '_theme.scss';

.to-main-container {
    height: 100%;
    width: 100%;
    transition: transform 0.25s;
}

.payment-currency {
    direction: rtl;
    display: inline-block;
}

.point-and-prepaid-option {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    line-height: initial;
}

.prepaid-icon-container {
    height: 19px;
    display: inline-block;
}

.wallet-cards-line {
    margin-top: 10px !important;
}

button.wallet-menu-button {
    display: flex;
    align-items: center;

    .prepaid-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 1.8rem;
        padding: 0.8rem;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-image: url('/assets/images/vouchers.png');
    }
}

// Eventually it was not needed
// .moving-aside {
//   transform: translateX(-25%);
// }

app-payment-dialog .embedded-payment-form-button:not(.mat-mdc-button-disabled) {
    background: map-get($default-theme, 100);
    color: map-get($default-theme, 500);
}

.marketplace-tags-panel {
    .mdc-label {
        margin-top: auto;
        margin-bottom: auto;
        
    }
    .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
        --mdc-checkbox-selected-icon-color: rgba(0, 0, 0, 0.54);
    }
}

.save-to-wallet .mat-checkbox-layout .mdc-label {
    white-space: normal !important;
}

._mobile {
    .moving-aside {
        transform: translateX(-70%);
    }

    .to-main-container {
        .mobile-title-text {
            width: 100%;
            text-overflow: unset; 
            
            &.simulate-button-margin {
                margin-inline-end: 24px; // 24px is the width of the menu icon
            }
        }
    }
}

.to-header-image {
    width: 100%;
    height: 3rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.to-step {
    height: 100%;
    display: flex;
    flex-direction: column;

    .search-container {
        padding: 0.5rem 1rem;
        margin-bottom: 0;

        .select-map-location {
            display: flex;
            flex-direction: row;
            margin-top: 0.5rem;
            justify-content: flex-start;

            .location-text {
                cursor: pointer;
                font-size: medium;
                text-decoration: underline;
                margin: 0 0.2rem 0 0.2rem;
            }
        }
    }

    .to-step-content {
        padding: 1rem;
    }
}

.to-step-header {
    padding: 1rem;
    border: 1px solid $separator;
    border-width: 1px 0;
    background: map-get($default-theme, 100);
    text-align: center;
    font-size: 1.2rem;
    font-weight: 500;
}

// neutralize error-prone scroll containers
// ._mobile {
//     mat-tab-body, .mat-mdc-tab-body-content, .tabit-order-tabs {
//         overflow: hidden !important;
//     }
// }

.to-step-content {
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    flex-grow: 1 !important;

    &._v-centered {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &._padded {
        padding: 1rem;
    }

    &._with-continue {
        padding-bottom: 1rem;
    }

    &.disclaimer {
        color: $absolute-grey;
    }

    .main-delivery-buttons-container {
        button {
            min-width: 18rem;
            max-width: 80vw;
            height: 5rem;
            border-radius: 5rem;
            margin: 2rem 0;
            font-size: 2rem;
        }
    }
}

.to-step-content-messages {
    display: block;
    padding: 1rem 1rem 0 1rem;

    > div {
        font-weight: 500;
        padding: 0 0 0.2rem 0;
        display: flex;

        > ._message {
            flex-grow: 1;
        }
    }
}

.to-checkout-button {
    position: absolute;
    bottom: 0;
    margin-bottom: 1.5rem;
    width: 150px;
    left: 0;
    float: left;
    border-radius: 0 30px 30px 0;
    border: none;
    height: 54px;
    font-size: 1.3rem;
    background-color: var(--primary-theme-500);
    display: flex;
    align-items: center;
    justify-content: center;

    body.ltr & {
        right: 0;
        left: auto;
        float: right;
        border-radius: 30px 0 0 30px;
    }
}

.module-bar {
    &.mobile-search-mode {
        height: 63px;
        @include cordova-margin-top;
    }

    .to-checkout-back-button {
        position: absolute;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.3rem;
        background: none;
        border: none;
        color: var(--primary-theme-500);
    }

    .extra-back-button {
        position: relative;
    }

    .extra-back-button + .reduced-text-overflow {
        width: 100%;
    }
}

.to-checkout-back-button {
    right: 0;
    border-radius: 30px 0 0 30px;
    width: 75px;

    &.custom-top {
        top: 2px;
    }

    body.ltr & {
        left: 0;
        right: auto;
        border-radius: 0 30px 30px 0;
    }
}

.nav-header-wrapper {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    height: 63px;
    background: var(--primary-theme-1100);
    z-index: 2;
}

._mobile._IOS {
    app-tabit-order.host-default {
        bottom: 0;
    }
}

:host-context(#default-branding) {
    @media (prefers-color-scheme: dark) {
        .nav-header-wrapper {
            background: map-get($default-theme, 0);
        }
    }
}

//-------------------------------------------------------------------->

@media (min-width: 896px) {
    .to-mobile-menu-wrapper {
        ::-webkit-scrollbar {
            display: block !important;
            width: 7px !important;
        }
    
        /* Track */
        ::-webkit-scrollbar-track {
            // box-shadow: inset 0 0 5px var(--theme-100);
            border-radius: 15px;
        }
    
        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: var(--primary-theme-500);
            border-radius: 15px;
        }
    
        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: var(--primary-theme-500) !important;
        }
    
        ::-webkit-scrollbar-track-piece:end {
            margin-bottom: 252px;  
        }
    }
}
.to-mobile-menu-wrapper {
    position:relative;
    width: 100%;
    height: 100%;
    display:flex;
    flex-direction:column;
    align-items:stretch;
}

.to-mobile-menu-nv-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--theme-0);
    border-bottom: 1px solid rgba(0, 0, 0, 0.20);

    .to-mobile-menu-nv .menu-category-name {
        color: map-get($primary-theme, 500);
    }

    .extra-info-button {
        line-height: 15px;
        padding: 0 0.3rem;
        
        &.search-button-icon {
            margin-bottom: 2px !important;

            .rtl & {
                margin-right: 10px;
            }
            .ltr & {
                margin-left: 10px;
            }
            // border-left: 1px solid var(--theme-500);

        }
    }
}

.to-menu-navigation {
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;

    > div {
        display: inline-block;
        position: relative;
        line-height: 40px;
        align-items: center;
        justify-content: center;
        padding: 0 10px;

        &:not(.skin-brand):not(:last-child):after {
            content: '';
            height: 20px;
            width: 1px;
            top: 12px;
            position: absolute;
            right: 0;
            background-color: var(--theme-500);
        }

        &.pressed {
            color: $link-color;
        }

        > span {
            white-space: nowrap;
            max-width: 90px;
            text-overflow: ellipsis;
            font-size: 1em;
            font-weight: 500;
        }
    }

    &:not(.scorlling) {
        > div {
            &.active {
                color: $link-color;
            }
        }
    }

    .rtl & {
        > div {
            &:not(:last-child):after {
                left: 0;
                right: auto;
            }
        }
    }
}

.basket-wrapper {
    padding: 10px;
    height: fit-content;
    margin: 0 10px 0 0;
    @include background-glass-effect;
}

.to-menu-container {
    .animation-container {
        position: absolute;
        top: 25%;
        right: 0;
        left: 0;
        z-index: 1;
    }

    &.last {
        min-height: 100%;
        padding-bottom: 80px;
        // background: var(--theme-0);
    }

    // &.saved-category {
    //     border: 6px solid map-get($primary-theme, 100);
    // }

    // ._desktop & {
    //     border: 1px solid rgba(0, 0, 0, 0.1);
    //     border-radius: 4px 4px 0 0;
    //     margin-bottom:1rem;
    // }
}
//-------------------------------------------------------------------->


._mobile .hide-mobile {
    display: none !important;
}
._desktop .hide-desktop {
    display: none !important;
}

._desktop .to-desktop-container {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    &:not(.start) {
        max-width: 100%;
        height: 100%;
        margin: 0 auto;
        padding: 5px 5%;
        justify-content: center;
        overflow: hidden;
    }

    .top-links {
        display: flex;
        flex-flow: row;

        .link {
            cursor: pointer;

            &:first-child {
                margin: 0 0 0 0.5rem;
            }
        }

    }
}

.to-desktop-module-bar {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    background: var(--theme-0);
    //border-bottom: 1px solid var(--theme-A300);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1) !important;

    .to-desktop-module-bar-top {
        > ._inner {
            overflow: hidden;
            height: 63px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }

        .title {
            font-size: 2rem;
            color: $primary-text;
            text-align: center;
            padding: 0 0 0 1rem;

            body.ltr & {
                padding: 0 1rem 0 0;
            }
        }
    }

    &._extended {
        .to-desktop-module-bar-top {
            > ._inner {
                height: 50px;
            }

            .title {
                font-size: 1.5rem;
            }
        }
    }

    .to-desktop-module-bar-bottom {
        background: var(--theme-800);
        color: var(--theme-100);
        font-size: 15px;
        font-weight: 400;

        > ._inner {
            overflow: hidden;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .header-item {
            display: flex;
            align-items: center;

            &._action {
                cursor: pointer;
                &:hover {
                    text-decoration: underline;
                }
            }

            > span {
                &:after {
                    content: ': ';
                    white-space: pre;
                }
            }

            &:not(:last-child):after {
                content: '|';
                display: inline-block;
                padding: 0 8px;
            }
        }
    }
}

.to-desktop-module-content {
    height: calc(100% - 3.25rem);
    padding: 0;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    // &._extended {
    //     padding-top: 100px;
    // }

    &._start {
        overflow: hidden;
        padding: 0 0 0 0;
    }
}

.to-main-container > .to-desktop-module-content:first-child {
    height: 100%!important;
}

.to-desktop-menu-nav {
    min-width: 120px;
    max-width: 120px;
    width: 120px;
    min-height: 300px;
    max-height: calc(100vh - 57px - 80px);
    overflow: hidden;
    overflow-y: auto;

    button:not(:empty) {
        display: block;
        margin-bottom: 10px;
        font-size: 16px;
        border-width: 0;

        &:not(.active) {
            background: var(--theme-300);
            color: var(--theme-1000);
        }
    }
}

.to-desktop-basket {
 
    min-height: 350px;
    max-height: 80vh;
    @include card-container;
    flex-direction: column;
    align-items: stretch;

    :host-context(._desktop) {
        min-width: 310px;
        max-width: 310px;
        width: 310px;
    }

    > ._content {
        /*display: block;
        flex-grow: 1;
        padding: 8px;
        overflow: hidden;
        overflow-y: auto;*/

        .to-basket-row {
            box-shadow: none !important;
            border-width: 0 !important;

            > ._image {
                width: 60px;
                max-width: 60px;
                min-width: 60px;
                height: 60px;
                max-height: 60px;
                min-height: 60px;

                &.no-image {
                    background-size: 60px !important;
                }
            }
        }
    }

    > ._info {
        background: var(--theme-100);
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-width: 0 1px;
        text-align: center;
    }

    > button {
        border-radius: 0 0 4px 4px;
    }
}

.to-desktop-basket-info {
    padding: 12px;
    font-size: 15px;

    > *:not(:first-child) {
        margin-top: 5px;
    }

    > ._row {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    .to-loyalty-inline {
        margin-bottom: 0;
        box-shadow: none !important;
        border: 1px solid rgba(0, 0, 0, 0.1);
    }
}

.to-desktop-checkout-nav {

    > div {
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.09) !important;
        background-color: var(--theme-900);
        color: var(--light-primary-text);
        border-radius: 4px;
        margin: 0 0 0.5rem;
        display: flex;
        align-items: center;
        font-size: 15px;
        font-weight: 600;
        height: 50px;
        cursor: pointer;
        padding: 0 15px;

        &:last-child{
            margin-bottom:1rem;
        }

        > ._caption {
            flex-grow: 1;
        }
    }
}

._desktop .to-desktop-checkout-step {
    font-size:15px;

    .to-step-content {
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-width: 0 1px;
        margin-top: 0.5rem;
    }

    .to-step {
        display: inline;
    }

    .to-step-header {
        padding: 0.5rem 1rem;
        border: 1px solid var(--theme-A300);
        border-width: 1px;
        border-radius: 4px 4px 0 0;
        background: var(--theme-100);

        &:before, &:after {
            display:inline-block;
            padding:0 10px;
            opacity: 0.5;
            content: '----';
        }
    }

    // .to-continue-bottom {
    //     position: static;
    //     padding: 0 0 0 0;

    //     button {
    //         height: 50px;
    //         border-radius: 0 !important;
    //     }
    // }

    .keyboard-spacer {
        display: none;
    }
}


//-------------------------------------------------------------------->
.to-menu-header {
    z-index: 1;
    position: relative;
    padding: 0.5rem 1rem;
    background: map-get($default-theme, 0);
    box-shadow: 0 5px 5px rgba(0,0,0,0.1);
    opacity: 0.9;

    &.with-action {
        cursor: pointer;

        &:hover {
            background: map-get($default-theme, 100);
        }
    }

    > .title {
        font-size: 22px;
        font-weight: 500;
        display: flex;
        align-items: center;
    }

    &.saved-header {
        background-color: map-get($primary-theme, 500);
        opacity: 1;

        .title {
            font-weight: bold;
        }
    }

    ._desktop & {
        box-shadow: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 4px 4px 0 0;
    }
}

.to-menu-message {
    padding: 1rem 1rem;
    border-bottom: 1px solid $separator;
    font-size: 0.9em;
    font-style: italic;
    //&:before{ content:'**';}
}

.to-menu-stockout {
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255, 0.5);
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}

.to-menu-offer {
    display: flex;
    align-items: center;
    background: map-get($default-theme, 0);
    padding: 10px;
    position: relative;
    cursor: pointer;

    &:hover {
        background: map-get($default-theme, 100);
    }

    > ._image {
        width: 80px;
        max-width: 80px;
        min-width: 80px;
        height: 80px;
        max-height: 80px;
        min-height: 80px;
        background: no-repeat center;
        border-radius: 5px;

        > ._counter {
            width: 24px;
            height: 24px;
            border-radius: 0 5px 0 5px;
            line-height: 25px;
            text-align: center;
            font-size: 14px;
            font-weight: 500;
            opacity: 0;
        }

        &.no-image {
            background-color: rgba(255, 255, 255, 0.5);
            background-size: 70px !important;
        }
    }

    &._active {
        ._counter {
            opacity: 1 !important;
        }
    }

    > ._body {
        padding: 0 10px;
        max-height: 80px;
        overflow: hidden;

        ._title {
            font-weight: 600;
            font-size: 15px;

            ._counter {
                width: 19px;
                height: 19px;
                border-radius: 4px;
                line-height: 20px;
                text-align: center;
                font-size: 13px;
                font-weight: 500;
                opacity: 0;
            }

            ._price {
                font-weight: 500;
                direction: rtl !important;
            }
        }

        ._description {
            margin-top: 5px;
            font-size: 12px;
            overflow: hidden;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }
    }
}

//-------------------------------------------------------------------->

.to-basket-row {
    display: flex;
    align-items: flex-start;
    border-radius: 5px;
    background: map-get($default-theme, 0);
    padding: 2px 10px;
    // margin-bottom: 8px;
    // box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.09) !important;

    > ._image {
        width: 80px;
        max-width: 80px;
        min-width: 80px;
        height: 80px;
        max-height: 80px;
        min-height: 80px;
        background: #ccc no-repeat center;
        border-radius: 5px;

        &.no-image {
            background-color: rgba(255, 255, 255, 0.5);
            background-size: 70px !important;
        }
    }

    > ._body {
        // padding: 0 10px;
        overflow: hidden;
        display: flex;
        flex-flow: column;
        width: 100%;

        ._top-container {
            display: flex;
            flex-flow: row;
            align-items: center;

            ._price {
                color: map-get($primary-theme, 500);
                font-weight: 400;
                direction: rtl !important;
            }
        }

        ._bottom-container {
            display: flex;
            flex-flow: column;

            ._price {
                font-weight: 400;
                direction: rtl !important;
            }
    
            ._summary {
                font-size: 12px;
                white-space: pre-line;
            }
        }
    }
}

//-------------------------------------------------------------------->



//-------------------------------------------------------------------->

.to-tbl-extras {


    .to-tbl-extras-header {
        padding: 10px 0;
        font-weight: 600;
        font-size: 1.1em;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }

    .to-tbl-extras-row {
        padding: 10px 0;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    ._desktop & {
        padding: 0 15px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        margin: 10px 0 0 0;

        .to-tbl-extras-header {
            font-size:16px;
        }

        .to-tbl-extras-row {
            font-size:15px;
        }
    }
}

//-------------------------------------------------------------------->

.to-tbl-info {
    width: 100%;

    tr {
        th {
            width: 100%;
            font-weight: normal;
            vertical-align: top;
        }

        td {
            padding: 2px 0;
            white-space: nowrap;
            vertical-align: top;
        }
    }
}
.to-comma-separated{
    >*:not(:last-child):after{
        content:', ';
        display:inline-block;
    }
}
//-------------------------------------------------------------------->
.to-expansion-panel {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.09) !important;

    &.mat-expansion-panel-spacing {
        margin: 10px 0;
    }

    // .mat-expansion-panel-header[aria-disabled='true'] .mat-expansion-panel-header-title, .mat-expansion-panel-header[aria-disabled='true'] .mat-expansion-panel-header-description {
    //     color: initial;
    // }

    mat-form-field {
        width: 100%;
    }

    &._no-pad {
        .mat-expansion-panel-body {
            padding: 0;
        }
    }
}

//-------------------------------------------------------------------->

.to-panel {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.09) !important;
    background: map-get($default-theme, 0);
    border-radius: 4px;
    margin-bottom: 10px;

    &._shadow {
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
    }

    > .to-panel-header {
        height: 50px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 15px;
        border-radius: inherit;
        font-size: 15px;
        font-weight: 600;
        border-bottom: 1px solid rgba(0, 0, 0, 0.09)
    }

    mat-form-field {
        width: 100%;

        .mat-mdc-form-field-infix {
            width: auto;
        }
    }

    > .to-panel-body {
        display: block;
        padding: 15px;
    }

    &.dark-theme {
        background: #444444;
    }
}
.language-selected {
    background: map-get($primary-theme, 500);
    color: map-get($default-theme, 0);
}
.language-switcher {
    width: 38px;
    height: 38px;
    button {
        width: 38px !important;
        height: 38px !important;
        padding: 6px 4px 0 0 !important;
        app-my-profile & {
            padding: 9px 0 0 0 !important;
        }
    }
    
    .mat-icon {
        height: 30px;
        width: 30px;
        margin: -0.5px -0.5px 5px 8px;
        svg {
            height: 100%;
            width: 100%;
        }

        app-web-header-search & {
            height: 35px !important;
            width: 35px !important;
        }
    }
}
.globe-language {
    .mdc-line-ripple {
        display: none;
    }
    .mat-mdc-text-field-wrapper {
        padding: 0!important;
        background-color: transparent !important;
        .mat-mdc-form-field-focus-overlay {
            opacity: 0 !important;
        }

        &:hover {
            .mat-mdc-form-field-focus-overlay {
                opacity: 0 !important;
            }
        }
    }

    .mat-mdc-form-field-infix {
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0!important;
        width: 40px;
    }
    .mat-mdc-form-field-underline {
        display: none;
    }
    .mat-mdc-form-field-subscript-wrapper {
        display: none;
    }
    .mat-mdc-form-field-flex {
        padding: 0;
        margin: 0;
    }
}

.globe-language-main {
    .mat-mdc-form-field-infix {
        padding: 0 !important;
        margin: -8px;
    }

    mat-icon {
        height: 35px;
        width: 52px;
    }
}

.card-move-animation {
    position: absolute !important;
    left: 0;
    top: 0;
    z-index: 4 !important;
    opacity: 1;
    transform: scale(1) translateX(0) translateY(0);
    outline-offset: 2px;

    &._start {
        transition: all ease-in 0.3s;
    }
}

.animation-overlay {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 3;
    height: 100vh;
    width: 100vw;
    background-color: #1b1b1b;
    opacity: 0;
    transition: opacity ease-in 0.3s;

    &._start {
        opacity: 0.8;
    }
}

.to-counter-badge {
    width: 30px;
    height: 30px;
    -o-object-fit: contain;
    object-fit: contain;
    border-radius: 15px;
    text-align: center;
    line-height: 31px;
    font-size: 18px;
    font-weight: bold;
}

.to-header-anim-highlight {
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-name: bounce;
    transform-origin: center bottom;
}

//---------------------------------------------------------------------->
// COMPONENTS
//---------------------------------------------------------------------->

.to-image-dialog {
    pointer-events: none !important;
    max-width: 100vw !important;

    mat-dialog-container {
        background: transparent !important;
        padding: 0;
    }
}

._desktop {

    // .sign-in-dialog-container{
    //     mat-dialog-container {
    //         min-height: 27rem!important;
    //         height: auto!important;
    //         overflow: scroll!important;
    //     }
    // }

    ._mobile:not(._IOS) {
        .to-main-container {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }
}
//---------------------------------------------------------------------->
// loyalty
//---------------------------------------------------------------------->

.to-loyalty-inline {
    position: relative;
    border-radius: 5px;
    background: map-get($default-theme, 0);
    padding: 0.25rem 1.25rem;
    margin-bottom: 1rem;
    border: 2px solid map-get($primary-theme, 500);
    @include card-shadow;
    font-size: larger;
    text-align:center;
    display: flex;
    justify-content: center;
    flex-flow: column;
    align-items: center;

    .points-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .points-icon {
        position: absolute;
        top: -30px;
        right: -15px;
        height: 60px;
        width: 60px;
    }

    .inner-container {
        position: relative;
        display: flex;
        flex-flow: row;
        justify-content: center;
        align-items: center;
        width: 100%;

        &.gift-card {
            flex-flow: column;
        }

        .points-disposal {
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
    
            .points {
                font-weight: bold;
            }
        }

        .next-icon {
            position: absolute;
            left: 0;
        }
    }

}
.to-loyalty-inline:empty{
    display: none;
}
//---------------------------------------------------------------------->
// leumi pay
//---------------------------------------------------------------------->

.alert.to-alert-promo {
    background: #800080 !important;
    border-color: #800080 !important;
    color: #fff !important;
}

#btnCheckout {
    .submit-btn-description {
        border-radius: 28px 0 0 28px;

        &.basket-source {
            border-radius: 0 0 0 28px !important;
        }

        body.ltr & {
            border-radius: 0 28px 28px 0 !important;

            &.basket-source {
                border-radius: 0 0 28px 0 !important;
            }
        }
    }

    .submit-btn-icon {
        border-radius: 0 28px 28px 0;

        &.basket-source {
            border-radius: 0 0 28px 0 !important;
        }

        body.ltr & {
            border-radius: 28px 0 0 28px !important;

            &.basket-source {
                border-radius: 0 0 0 28px !important;
            }
        }
    }
}

.trash {
    right: -5px !important;
    top: -8px !important;
}

.menu-item {
    border-radius: 20px !important;

    &.hide_category_images-layout,&.card-layout {
        .selected-item-indication {
            width: 100%;
        }
    }

    .selected-item-indication {
        border-top-left-radius: 20px !important;
        border-bottom-left-radius: 20px !important;

        body.ltr & {
            border-top-left-radius: unset !important;
            border-bottom-left-radius: unset !important;
            border-top-right-radius: 20px !important;
            border-bottom-right-radius: 20px !important;
        }
    }

    generic-image {
        // padding: 0.3rem !important;

        img {
            border-radius: 20px !important;
        }
    }
}

.to-summary-button,
.modifiers-group-item.single-selection-modifier,
.group-error-badge,
.basket-action-wrapper button,
#btnCheckout,
.gratuity-option,
.connect-button-wrapper button,
.shiny-button .shiny,
.payment-step-footer,
.readonly-payment,
.to-end-order-info mat-expansion-panel,
.to-loyalty-button,
.phone-input,
.phone-loyalty-input,
.to-loyalty-input,
.to-dialog-content .offer-sections .offer-section .offer-item-content .modifiers-group-container.rectangle-design .modifiers-group-items .modifiers-group-item,
mat-snack-bar-container.mat-mdc-snack-bar-container.add-to-basket-snackbar,
.cdk-overlay-pane.to-bundle-dialog mat-dialog-container app-tou-bundle .bundle-dialog-container mat-dialog-actions button,
.payment-field,
.close-rewards-button {
    border-radius: 28px !important;
}

//---------------------------------------------------------------------->
// DIALOGS
//---------------------------------------------------------------------->

.to-offer-backdrop {
    ._desktop & {
        background: rgba(0,0,0,0.7);
    }
}

.cordova._IOS .to-offer-dialog app-tou-offer {
    height: initial;
    position: absolute;
    top: env(safe-area-inset-top, 35px);
    bottom: env(safe-area-inset-bottom, 35px);
    transform: translateZ(0); // necessary for the footer (.to-dialog-actions) relative fixed position
}

.cordova._IOS .to-offer-dialog .mat-mdc-dialog-surface {
    background: map-get($palette-neutral, 10);
    box-shadow: none;
    height: initial;
    position: absolute;
    top: env(safe-area-inset-top, 35px);
    bottom: env(safe-area-inset-bottom, 35px);
    transform: translateZ(0); // necessary for the footer (.to-dialog-actions) relative fixed position
}

.cordova._IOS .to-offer-dialog app-tou-offer-item-dialog {
    height: initial;
    position: absolute;
    top: env(safe-area-inset-top, 35px);
    bottom: env(safe-area-inset-bottom, 35px);
    transform: translateZ(0); // necessary for the footer (.to-dialog-actions) relative fixed position
}

._desktop {
    .to-offer-dialog {
        animation-duration: 0.5s;
        width: 100% !important;
        max-width: 600px !important;
        height: 90%;

        mat-dialog-container {
            background: transparent !important;
            padding: 0;
        }
    }
}

._mobile {
    &.android {
        .to-offer-dialog { 
            .to-dialog-content {
                top: 5.5rem;
            }
        }
    }
    .to-offer-dialog {
        position: fixed !important;
        top: 0;
        bottom: 0;
        background: map-get($default-theme, 0);
        width: 100vw !important;
        min-width: 100vw !important;

        mat-dialog-container {
            display: inline;
            background: transparent !important;
            padding: 0;
        }

        .to-mobile-dialog {
            .to-dialog-header {
                height: 3rem;
                // padding: 1rem !important;

                ._close .close-button {
                    align-items: center;
                    display: flex;
                }
            }

            .to-dialog-content {


                position: absolute;
                top:  3.5rem;
                right: 0;
                bottom: 3.5rem;
                left: 0;
                padding: 0;
                // relevant only to offer-history-dialog
                // .offer-header {
                //     margin-top: 1rem;
                // }
            }

            .to-dialog-actions {
                height: 3.4rem;
                position: fixed;
                bottom: 0;
                z-index: 3;
                margin: 0 !important;
                padding: 0.3rem !important;
            }
        }
    }

    &:not(.cordova) {
        .to-offer-dialog {
            .to-mobile-dialog {
                // .to-dialog-header {
                //     padding: 1rem !important;
                // }

                .to-dialog-content {
                    position: absolute;
                    top: 3rem;
                }
            }
        }
    }

    .third-level-dialog {
        ._header_container {
                width: 90%;
        }
        .to-dialog-actions {
            padding-left: 1rem;
            padding-right: 1rem;
        }
        .dialog-add-button {
            min-width: 10rem;
            font-size: 1.2rem;
        }
        .dialog-clear-button {
            font-size: 1.2rem;
        }
        .dialog-cancel-button {
            min-width: 10rem;
        }
    }

    .to-inner-dialog {
        position: fixed !important;
        max-width: 85vw !important;
        width: 85vw !important;

        .to-dialog-header {
            padding: 0.5rem 0;

            ._close .close-button {
                width: 1.5rem;
            }
        }

        .to-dialog-actions {
            padding: 0.5rem;
            .dialog-cancel-button {
                height: 2.5rem;
                width: 40%;
            }

            .dialog-add-button {
                height: 2.5rem;
                width: 55%;
                justify-content: center;
                background: map-get($primary-theme, 500);
            }
        }
    }
}

.future-orders-dialog {
    .hour-container {
        direction: ltr;
    }
    
    mat-dialog-container {
        top: calc(#{$toolbar-height} - 8px);
        display: inline;
        @include cordova-dialog-margin-top;
        padding: 0;
        overflow: hidden;
        
    }

    ._mobile & {
        mat-dialog-container {
            border-radius: 0;
        }
        width: 100% !important;
        min-width: 100% !important;
        max-width: 100% !important;
        height: 100%;
        min-height: 100% !important;
        max-height: 100% !important;

        .main-container {
            left: 0;
            right: 0;
            width: 100% !important;
            bottom: 0;
            .bottom-container {
                margin-bottom: 20vh;
            }
        }
        .data-container {
            -webkit-overflow-scrolling:touch;
            width: 100% !important;
            height: calc(60vh - #{$toolbar-height}) !important;
            max-height: calc(100vh - 17rem);
        }
        .title-container {
            width: 100% !important;
        }
        .header-container {
            margin: 0 !important;
        }
    }

    ._desktop & {
        @include background-matte-glass-effect;
        @include light-border;
        @include header-container;
        animation-duration: 0.5s;
        width: 60% !important;
        margin: 0 25% 0 0;
        max-width: 800px !important;
        height: 85%;
        overflow-x: scroll;
        display: block;
        mat-dialog-container {
            background-color: transparent;
            margin-top: 0!important;
        }
    }

    ._desktop.ltr & {
        margin: 0 0 0 25%;
    }
}

.future-orders-dialog  {
    .data-container ::-webkit-scrollbar {
        display: block !important;
        width: 7px !important;
    }
    .data-container ::-webkit-scrollbar-track {
        border-radius: 15px;
    }
    .data-container ::-webkit-scrollbar-thumb {
        background: var(--primary-theme-500);
        border-radius: 15px;
    }
    .data-container ::-webkit-scrollbar-thumb:hover {
        background: var(--primary-theme-500);
    }
}

.scrollbar-container {
    ::-webkit-scrollbar {
        display: block !important;
        width: 7px !important;
    }
    ::-webkit-scrollbar-track {
        border-radius: 15px;
    }
    ::-webkit-scrollbar-thumb {
        background: var(--primary-theme-500);
        border-radius: 15px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: var(--primary-theme-500);
    }
}

to-profile-dialog {
    .dialog-container {
        padding: 10px;
    }
}

.to-splash-dialog {
    @include background-matte-glass-effect;
    @include light-border;

    mat-dialog-container {
        display: inline;
        background: transparent;
        padding: 0;
        overflow: hidden;
    }

    &:not(.sites-dialog) ._mobile & {
        width: 80vw !important;
        min-width: 80vw !important;
        max-width: 80vw !important;
        height: 80vh;
        min-height: 80vh !important;
        max-height: 80vh !important;
    }

    ._desktop & {
        animation-duration: 0.5s;
        width: 60% !important;
        margin: 0 25% 0 0;
        max-width: 800px !important;
        height: 85%;
    }

    ._desktop.ltr & {
        margin: 0 0 0 25%;
    }
}

.to-map-dialog {
    @include background-matte-glass-effect;
    @include light-border;
    animation-duration: 0.5s;
    width: 100% !important;
    margin: 0 25% 0 0;
    max-width: 1000px !important;
    //height: 75%;
    height: auto;

    mat-dialog-container {
        display: inline;
        padding: 0;
        overflow: hidden;
    }

    ._desktop.ltr & {
        margin: 0 0 0 25%;
    }
}
.gift-it {
    .mat-mdc-dialog-surface {
        background-color: transparent!important;
    }
}

.to-splash-dialog.sites-dialog {
    height: initial;

    .mat-mdc-dialog-surface {
        &.mdc-dialog__surface {
            background: transparent!important;
        }
    } 

    .mat-mdc-dialog-container {
        overflow: initial;
    }

    .dialog-container {
        align-items: center;

        .close-button {
            height: 1.5rem;
            width: 1.5rem;
            position: absolute;
            top: -0.85rem;
            right: -0.8rem;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            margin: 0;
            line-height: initial;
            background-color: map-get($default-theme, 0);
            border-radius: 100%;
            border: 1px solid map-get($default-theme, 1000);

            mat-icon {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                line-height: initial;
            }
        }
    }
}

.to-splash-dialog.to-map-dialog {
    width: 100%;
    height: 100%;
    background: none;
}

body.rtl .to-splash-dialog.sites-dialog {
    .dialog-container {
        .close-button {
            right: initial;
            left: -0.8rem;
        }
    }
}

._mobile .to-splash-dialog {
    @media screen and (max-height: 768px) {
        margin-top: 5vh;
        
        .item-wrapper {
            max-height: 65vh !important;

            @media screen and (max-height: 550px) {
                max-height: 60vh !important;
            }
        }
    }

    &.sites-dialog {
        width: 85vw !important;
        max-width: 85vw !important;
    }
}

//---------------------------------------------------------------------->
// BRAND
//---------------------------------------------------------------------->

$to_counter_bg: $primary-text;
$to_counter_txt: map-get($default-theme, 0);

.to-text-highlight {
    color: $primary-text;
}

.to-counter-bg {
    background-color: $to_counter_bg;
    color: $to_counter_txt;
}

/* ADDRESS ROW */

.address-title, .sites-title {
    font-size: x-large;
    margin: 0 1rem 0 0;

    :host-context(body.ltr) & {
        margin: 0 0 0 1rem;
    }
}

.address-title {
    margin: 0 1rem 1rem 1rem;
    line-height: 22px;

    &.search-results {
        margin: 1rem;
    }
}

.address {
    height: 4rem;
    border-bottom: 1px solid $separator;
    cursor: pointer;
    width: 100%;
    background:$background-light-with-opacity;

    .address-row {
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        align-items: center;

        .formatted-address {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            width: 95%;
        }

        mat-icon, img {
            margin: 0 0 0 1rem;

            .ltr & {
                margin: 0 1rem 0 0 !important;
            }
        }

        img {
            height: 1rem;
        }
    }

    .arrow-button, .delete-button {
        height: 4rem;
        line-height: 3rem;
        padding: 0 0.5rem;
    }

    .star-button { 
        height: 4rem;
        line-height: 3.7rem;
        padding: 0 0.5rem;
    }

    .arrow-button {
        mat-icon {
            line-height: 4rem;
        }
    }
}

.mat-mdc-select-languages-panel {
    ._mobile.rtl &{
        transform: scaleY(1) translateY(14px) !important;
    }
    ._desktop.rtl &{
        transform: scaleY(1) translateY(18px) !important;
    }
}

header-links {
    .to-header-links.desktop > div {
        display: flex;
        align-items: center;
    }
    .desktop.lang-placeholder {
        min-width: 7rem;
    }
    .mobile-web {
        > div {
            margin-top: 2px;
        }
    }
    
    .to-header-links {
        .mat-mdc-form-field-underline {
            display: none;
        }
    
        .mat-mdc-text-field-wrapper {
            padding: 0;
        }
    
        .mat-mdc-select-arrow-wrapper {
            display: contents;
        }

        .mat-mdc-form-field-infix {
            font-size: small;
            border: none;
            width: 90px;
            padding: 0.25em;

            body.ltr & {
                padding: unset;
            }
        }
    
        .mat-mdc-form-field-appearance-fill {
            .mat-mdc-form-field-flex {
                padding: 0;
                background-color: transparent;
            }
        }
    
        .mat-mdc-select-value {
            color: white;
        }
    
        .mat-mdc-select-panel {
            opacity: 0.9;
        }
    
        .mat-mdc-select-languages-panel {
            display: none;
        }
        .mat-mdc-select-arrow {
            color: white;
        }
    
        .rtl & {
            .mat-mdc-select-arrow {
                margin: 1px 4px;
            }
    
            .mat-mdc-select-languages {
                padding-right: 22px;
            }
    
            .mat-mdc-select-min-line {
                margin: 0 5px;
            }
        }

        .ltr & {
            .mat-mdc-select-arrow {
                margin: 1px -4px;
            }
    
            .mat-mdc-select-languages {
                padding-left: 26px;
            }
    
            .mat-mdc-select-min-line {
                margin: 0 0 0 5px;
            }
        }
    }
}

sign-in-dialog {
    .extra-field-container {
        position: relative;
        height: 100px;
        .extra-field {
            position: absolute;
            width: 100%;
        }
    }
}

.mat-mdc-select-custom {
    .mat-mdc-select-value-text,
    .large-payment-details-container,
    .mat-mdc-select-placeholder {
        color: #888588 !important;
    }
}

.grecaptcha-text {
    padding: 5px 20px;
    direction:ltr;
    text-align: center;
    font-size: 12px;
    font-style:italic;
    display: inline;
    color: var(--theme-500) !important;

    a {
        color: map-get($primary-theme, 500) !important;
    }
}

.cordova._ANDROID {
    .to-dialog-content {
        top: max(5rem, calc(3rem + env(safe-area-inset-top))) !important;
    }
}