@use '@angular/material' as mat;
@import 'variables.scss';
@import 'utilities.scss';

@import 'tabitOrder.scss';
@import 'animations.scss';

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-mdc-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-component-typographies();
@include mat.core();
@include mat.all-component-themes($light-theme);
$theme-config: mat-get-color-config($light-theme);

@import 'overrides.scss';

@mixin dark-theme-styles {
    color: #FFF !important;

    --mdc-dialog-container-color: #424242;
    --mdc-list-list-item-label-text-color: #FFF;

    .mat-mdc-unelevated-button:not(:disabled) {
        color: #000;
    }

    mat-list-item {
        ._title.text-truncate {
            color:#FFF !important;
        }
    }
    //@include mat.all-component-colors($dark-theme);
    $theme-config: mat-get-color-config($dark-theme); // Get color configuration for dark theme
}

// it was decided to prevent any dark-theme style if custom-branding is applied
#default-branding .dark-theme {
    @include dark-theme-styles;
}

@media (prefers-color-scheme: dark) {
    #default-branding {
        // Matazl Tov! For the first time ever, we decide the color of the whole text by ourselves, not by "material-drawer" !
        // The color for the light theme is still decided by bootstrap 'reboot'
        body {
            // This will generate a single warning (since we have the same under the .dark-theme class above), but it's required for the material components to get the dark-mode theme.
            @include dark-theme-styles;

            .mat-mdc-stepper-horizontal {
                background-color: initial !important;
            }
        }

        .mat-mdc-bottom-sheet-container {
            mat-icon {
                &:not(.pay-icon) {
                    svg {
                        filter: brightness(100);
                    }
                }
            }
        }
    }
}

/* ## */
html, body {
    overscroll-behavior: contain;
}

.html-mobile-app {
    height:100vh !important;
}

html {
    width:100vw;
    height:100%;
    margin:0;
    padding:0;
    font-size:16px;

    @media only screen and (min-width: 769px) {
        // Desktops
        font-size: 19px;
    }

    @media only screen and (min-width: 414px) {
        // iPhone 8 Plus
        // 2020-01-14: Decided to have the same font size (16px) on iPhone Plus too, because having a different one in Mobile makes the handling of the scrolling and header height on Web much more complex.
        //font-size: 19px;
    }

    @media only screen and (max-width: 340px) {
        // iPhone 5
        font-size: 13px;
    }

    &.to-module {
        font-size:16px;
    }

}

.to-module:has(._ANDROID) {
    font-size:14.5px;
  }

body {
    position:absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;

    &.cordova._IOS {
        position:fixed;
        bottom: 0;
    }

    // 2020-02-02: Since we need the body to remain transparent for the QR Code Scanner (using by qr-scanner.ts) - we apply the background to the .main-app-component class, in the app-component.scss
    // A weird bug (probably in in the QR Code Scanner) adds a background-style: transparent to the 'html' and 'body', and it's very hard to remove it forcefully, even by trying after navigation end.
    // So the solution of applying the background color to the main-app-component seems to be the best solution, and also prevents the need of adding .body-trans class (with transparentcy) to the body.
    // Instead, we apply the trans-overlay on-deman to the .main-app-component.
    //background: $background-light;
    // We MUST still define a transparent background for this to work.
    background: transparent;

    &.tabit-order-module {

        &._IOS:not(.cordova) {
            height: 100%;
            overflow-y: auto;
            position: fixed;
        }

        &.ios-web {
            height: 100%;
            overflow: hidden !important;
            position: absolute !important;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
        // needed to prevent a visual 'flick' when OSK closes
        background-color: $background-light;
    }

    overflow: hidden;
    -webkit-text-size-adjust: none;
    -ms-text-size-adjust: none; /* prevent webkit from resizing text to fit */

    &, * {
        font-family: 'Rubik',sans-serif;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        -ms-tap-highlight-color: rgba(0,0,0,0);

        // 08-11-2023 - Decided to remove
        // it's needed to prevent mouse gestures
        // &:not(input):not(textarea) {
        //     -webkit-user-select: none;
        //     -khtml-user-select: none;
        //     -moz-user-select: none;
        //     -ms-user-select: none;
        //     user-select: none;
        // }

        caret-color: map-get($default-theme, 1000);
        --mat-option-label-text-font: 'Rubik',sans-serif;
        .mdc-list-item__primary-text {
            font-family: var(--mat-option-label-text-font);
        }
    }

    :focus {
        outline:none!important;
        &:not(.enable-focus-shadow) {//ori: you have made it impossible to have clickable shadowed items I have added an exclusion rule
            box-shadow: none!important;
        }
    }

    // outline the focused element only on keyboard interaction, for accessibility purposes
    &.accessibility-focus-visible {
        :focus-visible {
            outline: 2px solid #3276d1 !important;
            border-radius: 5px;
            
        }
    }

    ::-webkit-scrollbar {display:none!important;width:0!important;height:0!important};

    app-root {
        h1, h2, h3, h4 {
            display: block;
            padding: 0;
            margin: 0;
            font-weight: normal
        }

        width: 100vw;
        position: absolute;
        top: 0 !important;
        left: 0;
        bottom: 0;
        z-index: 0;

        .mat-mdc-button:hover .mat-mdc-button-focus-overlay {
            opacity: 0 !important
        }

        button {
            cursor: pointer;
            @include highlight-by-opacity;
        }
    }

    &.ac-pointer-white {
        cursor: url('assets/images/mousew.png'), pointer !important;
    }

    &.ac-pointer-black {
        cursor: url('assets/images/mouseb.png'), pointer !important;
    }

    &.ac-zoom > :not(accessibility-menu) {
        zoom: 1.2;
    }

    &.ac-gray {
        background-blend-mode: luminosity;
        > :not(accessibility-menu) {
            -webkit-filter: grayscale(100%);
            -moz-filter: grayscale(100%);
            -ms-filter: grayscale(100%);
            -o-filter: grayscale(100%);
            filter: grayscale(100%);
        }
    }
    
    &.ac-contrast-dark {
        /*background-blend-mode: luminosity;*/
        background: #fff !important;
        > :not(accessibility-menu) {
            -webkit-filter: invert(1);
            -moz-filter: invert(1);
            -ms-filter: invert(1);
            -o-filter: invert(1);
            filter: invert(1);
        }
    }

    &.ac-contrast-light {
        background: #fff !important;
        > :not(accessibility-menu) {
            -webkit-filter: grayscale(100%);
            -moz-filter: grayscale(100%);
            -ms-filter: grayscale(100%);
            -o-filter: grayscale(100%);
            filter: grayscale(100%);
        }
    }

    &.ac-font {
        font-family: Arial, Helvetica, sans-serif !important;
        font-size: 1.5rem !important;
    }

    &.ac-links {
        text-decoration: underline !important;
    }
}

.cdk-global-scrollblock {
    position: static;
    overflow: hidden !important;

    body {
        overflow: hidden !important;
    }
}

/*
    start dirty styles
*/
.rtl {
    mat-dialog-container{
        direction:rtl;
    }
}

.ltr-direction {
    direction: ltr;
}

.rtl-direction {
    direction: rtl;
}

.reduced-font-size {
    font-size: 1.75rem !important;
}

.reduced-overflow {
    overflow: initial !important;
}
.reduced-text-overflow {
    text-overflow: initial !important;
}
/*
    end start dirty styles
*/
// QR Scanner height
zxing-scanner video {
    height: 100% !important;
}

.module-bar {

    mat-icon {
        width: 28px;
        height: 28px;
    }
    // Those elements affect the whole app
    // specificaly the z-index update here, affected the dashboard at the app (Items overflowing it upon scroll)
    position:absolute;z-index:2;top:0;left:0;right:0;
    background:$background-light-with-opacity;
    > ._inner {
        @include cordova-margin-top;
        overflow: hidden;
        padding:0 1rem;
        height: $toolbar-height;
        box-sizing:border-box;
        display: flex !important;
        justify-content: flex-start !important;
        align-items: center !important;
        .title {
            font-size: 1.7rem;
            color: $primary-text;
            text-align: center;
            padding: 0 0 0 1rem;
        }
        .logo {height:45%;margin:0 1rem}
        .back-button.mat-icon-button {
            height: $toolbar-height;
            margin:0 -1rem 0 0;
            border-radius: 0.5rem;
        }
        .share {
            position: relative;
            top: 3px;
            padding: 0 1rem;
            color: $absolute-grey;
        }
    }
    &.lighter {
        > ._inner {
            background: rgba(0,0,0,0.4);
        }
    }
    &.pay {
        > ._inner {
            background:rgba(156,128,168,0.38);
        }
    }
}

.mat-mdc-button-focus-overlay {
    background-color: transparent!important;
}

._mobile:not(.cordova) { 

    button, mat-icon {
        touch-action: manipulation;
    }

    .module-bar {
        > ._inner {

            .title {
                padding: 0 0.5rem 0 0.5rem;
            }

            .module-bar-close-icon {
                min-width: 30px;
            }

            .module-bar-language-icon {
                // margin-top: 5px;
                // height: 28px;
                // min-width: 30px;
            }

            .module-bar-close-icon {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

.app-bottom-aligned {
    position: absolute;
    left: 0;
    bottom: 4rem;
    right: 0;
    overflow:hidden;
    z-index: 1;
    @include cordova-margin-bottom;
}

.app-bottom-links {
    position: fixed;
    left:0;
    bottom:0;
    right:0;
    > div {
        height: $bottombar-height;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
    }
    overflow:hidden;
    z-index:3;    
    border-top: 1px solid $separator;
    background: $background-default;

    footer-links-button {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        background:transparent;
        font-size:0.8rem;
        border-width:0;
        line-height:1rem;
        white-space:nowrap;
        border-radius: 0.25rem;
    }

    button {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        background:transparent;
        font-size:0.8rem;
        border-width:0;
        line-height:1rem;
        white-space:nowrap;
        color:#979797;
        border-radius: 0.25rem;
        
        mat-icon {
            width:2rem;
            height:2rem;
        }
        
        // Changed animation speed from 600ms to 400ms to accomodate issues on low-cpu androids (the active color remained when moving fast between screens)
        transition: background-color 400ms ease-in-out;
        transition-delay: 0s;
        &:active {
            background-color: map-get($default-theme, O1);
            transition: background-color 0s ease-in-out;
        }

        &.disabled {
            @include disabled-by-opacity;
        }
    }
}

cdk-virtual-scroll-viewport.module-content, .module-content {
    position:absolute;
    top:0;
    left:0;
    bottom:0;
    right:0;
    padding:$toolbar-height 0 $bottombar-height 0;
    @include cordova-module-content;
    overflow: auto;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    &.without-padding {
        padding: 0 !important;
    }

    &.app-bottom-links-hidden {
        padding-bottom:0 !important;
    }
}

mat-progress-spinner.module-spinner {
    z-index:10;
    position:absolute;top:calc(50vh - 36px);left:calc(50vw - 36px);opacity:0.5;
    // circle {
        //stroke: map-get($default-theme, 0);
    // }
}

.fab-fixed-bottom {
    position:absolute;
    z-index:2;
    position:absolute;
    left:20px;
    bottom: calc(#{$bottombar-height} + 1.8rem);
    @include cordova-margin-bottom;
}
.fab-fixed-top {
    position:absolute;
    z-index:2;
    position:absolute;
    left:20px;
    top:20px;
    @include cordova-margin-top;
}
.drop-filters {
    position:absolute;z-index:2;
    top: $toolbar-height;
    bottom: 0;
    padding:0 1rem;
    background: $background-light-with-opacity;
    overflow: auto;

    @include cordova-drop-filters;
    
    text-align:right;
    [dir="rtl"] & {text-align:left}
    
    > div {
        display: flex;
        flex-flow: column nowrap;

        padding-bottom: $bottombar-height;
        @include cordova-margin-bottom;

        mat-form-field {width:100%}

        mat-chip {
            height: 2.4rem;
            justify-content: center;
            white-space: nowrap;
            overflow: hidden;
        }

        .areas {
            mat-chip {
                margin: 4px 2px;
                flex: 1 1 auto;
            }
        }

        .categories {
            mat-chip {
                margin: 4px 2px;
                flex: 1 1 30%;
            }
        }

        .tags {
            mat-chip {
                margin: 4px 2px;
                flex: 1 1 auto;
            }
        }

        .services {
            mat-chip {
                margin: 4px 2px;
                flex: 1 1 auto;
            }
        }

        .filters-title {
            font-weight: bold;
        }
    
        .filters-container {
            padding: 0.5rem 0;
            min-width: 5rem;
        }
    
        .filters-divider {
            border-bottom: 1px solid $separator;
            margin: 0.5rem;
        }
    }
}

.fixed-search {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-content: center;
    height: 3.2rem;
    padding: 0 1rem;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    @include cordova-margin-top;
    background: $background-light-with-opacity;
    z-index:1;
    transform:translateY($toolbar-height);
    opacity:1;
    transition: all 0.2s ease-in-out 0s;
    
    &.with-address {
        height: 6.4rem;
    }

    &.down {
        opacity:0;
        transform:translateY(0);
    }
    &.without-animation {
        transition: none;
    }

    .input-group {
        margin: 0 0 0.3rem;

        .input-group-text {
            color:rgba(114, 114, 114, 0.7);
        }
    }
    
}

@media (prefers-color-scheme: dark) { 
    .module-bar-language-icon {
        stroke: white;
    }
}

.show-more-sites {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    min-height: 5rem;
    margin: 2rem 0;
    
    @media only screen and (max-width: 767px) {
        margin-bottom: 9.5rem; // 7.5rem for the Safari bottom toolbar + 2rem of the original show-more-sites
    }

    .loading-label {
        color: map-get($default-theme, 600);
        text-align: center;
        padding: 1rem;
    }
}

$foreground: map-get($theme-config, foreground);

.mat-mdc-form-field .mdc-notched-outline__notch {
    border-radius: .000001px;
    border-right: none !important;
    border-left: none !important;
}

.without-brand {
    .mat-mdc-select-arrow {
        color: mat.get-color-from-palette($foreground, secondary-text) !important;
    }

    .mat-mdc-form-field-ripple {
        background-color: mat.get-color-from-palette($foreground, secondary-text) !important;
    }

    &.mat-mdc-primary .mat-mdc-option.mat-mdc-selected:not(.mat-mdc-option-disabled), .to-language-menu-selected {
        color: mat.get-color-from-palette($foreground, text) !important;
    }

    .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text, 
    .mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after {
        color: mat.get-color-from-palette($foreground, text) !important;
    }

    .mdc-list-item__primary-text {
        font-size: 20px;
    }
}

._mobile:not(.cordova) {     
    .terms-link {
        .mat-mdc-checkbox-inner-container {
            margin-top: 3px;
        }
        .mat-mdc-checkbox-label {
            display: flex;
            flex-wrap: wrap;
        }
    }
}


.screen_reader_only_text {
    position: absolute !important; /* Outside the DOM flow */
    height: 1px; width: 1px; /* Nearly collapsed */
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE 7+ only support clip without commas */
    clip: rect(1px, 1px, 1px, 1px); /* All other browsers */
}

.chrome-link-disable {

    span {
        white-space: normal !important;
    }

    chrome_annotation {
        border: none !important;
    }
}

// In case you want to add scroll bar inside a nested iframe
// body.iframe {
//     overflow-y: scroll;

//     ::-webkit-scrollbar {
//         display: block !important;
//         width: 3px !important;
//     }

//     ::-webkit-scrollbar {
//         width: 20px;
//     }

//     __<<ngThemingMigrationEscapedComment9>>__
//     ::-webkit-scrollbar-track {
//         // box-shadow: inset 0 0 5px var(--theme-100);
//         border-radius: 15px;
//     }

//     __<<ngThemingMigrationEscapedComment10>>__
//     ::-webkit-scrollbar-thumb {
//         background: var(--primary-theme-500);
//         border-radius: 15px;
//     }

//     __<<ngThemingMigrationEscapedComment11>>__
//     ::-webkit-scrollbar-thumb:hover {
//         background: var(--primary-theme-500);
//     }
// }
